import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { PrimaryButton, Seo } from 'components';
import Header from 'components/Header';
import { useQuizData } from 'utils/hooks';
import { useRouter } from 'apis/history';
import ResultsProgress from 'components/ResultsProgress';
import Current from 'assets/icons/chart-icons/current-weight.svg';
import After from 'assets/icons/chart-icons/after-weight.svg';
import Carni from 'assets/icons/chart-icons/carni.svg';
import Other from 'assets/icons/chart-icons/other.svg';
import Arrow from 'assets/icons/quiz/personal-summary-arrow.svg';

import {
  Area,
  CartesianGrid,
  ComposedChart,
  defs,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const chartData = [
  { name: 'Page A', uv: 1500, pv: 0, amt: 1500, cnt: 0 },
  { name: 'Page B', uv: 1520, pv: 0, amt: 1420, cnt: 0 },
  { name: 'Page C', uv: 1200, pv: 0, amt: 900, cnt: 0 },
  { name: 'Page D', uv: 1500, pv: 0, amt: 800, cnt: 0 },
  { name: 'Page E', uv: 1200, pv: 0, amt: 700, cnt: 0 },
  { name: 'Page F', uv: 1500, pv: 0, amt: 500, cnt: 0 },
  { name: 'Page F', uv: 1200, pv: 0, amt: 450, cnt: 0 },
  { name: 'Page G', uv: 1500, pv: 0, amt: 200, cnt: 0 },
];

const ResultsPersonalized: FC = () => {
  const data = useQuizData('resultsPersonalized');
  const { goToResultsPlanInfo } = useRouter();
  const { isMobile } = useQuery();

  // State to manage visibility of the Current and After icons
  const [showCurrent, setShowCurrent] = useState(false);
  const [showAfter, setShowAfter] = useState(false);

  // useEffect to trigger the delay
  useEffect(() => {
    // Show the Current icon after 1 second
    const currentTimer = setTimeout(() => setShowCurrent(true), 200);
    // Show the After icon after 1.5 seconds
    const afterTimer = setTimeout(() => setShowAfter(true), 800);

    // Cleanup timeouts when component unmounts
    return () => {
      clearTimeout(currentTimer);
      clearTimeout(afterTimer);
    };
  }, []);

  return (
    <>
      <Seo />
      <Outter>
        <Header onBack={() => window.history.back()} sticky={false} />
        <ResultsProgress step={3} />
      </Outter>
      <Main>
        <Container>
          <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />

          <SvgContainer>
            <BubbleContainerCurrent visible={showCurrent}>
              <YouCurrent>
                {data?.now}
                <Arrow />
              </YouCurrent>
              <Bubble />
            </BubbleContainerCurrent>
            <BubbleContainerAfter visible={showAfter}>
              <YouText>
                {data?.now}
                <Arrow />
              </YouText>
              <Bubble />
            </BubbleContainerAfter>

            <ResponsiveContainer width="100%" height={200}>
              <ComposedChart
                width={400}
                height={200}
                data={chartData}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid
                  stroke="#1415151A"
                  strokeDasharray="2.13 2.13"
                  vertical={false}
                />
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0.048593" stopColor="#D9333F" />
                    <stop offset="0.394342" stopColor="#FBBF77" />
                    <stop offset="0.70518" stopColor="#F3ED80" />
                    <stop offset="1" stopColor="#BFF4A4" />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="amt"
                  stroke="url(#colorUv)"
                  fill="url(#colorUv)"
                  strokeWidth={3}
                />
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#F04D41"
                  strokeDasharray="10 5"
                  strokeWidth={3}
                  strokeLinecap="round"
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <IconsContainer>
              <InnerIcons>
                <Carni />
                {data?.with}
              </InnerIcons>
              <InnerIcons>
                <Other />
                {data?.other}
              </InnerIcons>
            </IconsContainer>
          </SvgContainer>

          <BottomText>{data?.bottomText}</BottomText>
          <ButtonContainer>
            <ContinueButtonStyled onClick={goToResultsPlanInfo}>
              {data?.buttonTitle}
            </ContinueButtonStyled>
          </ButtonContainer>
        </Container>
      </Main>
    </>
  );
};

export default ResultsPersonalized;

const BubbleContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.57rem;
  top: -40px;
  transition: left 1.5s ease-out;
  @media ${tablet} {
    top: -38px;
  }
`;
const BubbleContainerCurrent = styled(BubbleContainer)<{ visible: boolean }>`
  align-items: flex-start;
  position: absolute;
  top: 30px;
  left: 24px;
  z-index: 3;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props =>
    props.visible ? 'translateY(0)' : 'translateY(-20px)'};
  transition: opacity 0.5s ease, transform 0.5s ease;
  @media ${tablet} {
    left: 16px;
  }
`;

const BubbleContainerAfter = styled(BubbleContainer)<{ visible: boolean }>`
  top: unset;
  position: absolute;
  bottom: 147px;
  right: 17%;
  z-index: 3;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props =>
    props.visible ? 'translateY(0)' : 'translateY(-20px)'};
  transition: opacity 0.5s ease, transform 0.5s ease;
  @media ${tablet} {
    bottom: 123px;
    right: 15%;
  }
`;

const YouText = styled.div`
  display: inline-flex;
  padding: 0.25rem 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #727373;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  text-wrap: nowrap;
  svg {
    position: absolute;
    bottom: -6px;
    path {
      fill: #727373;
    }
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const YouCurrent = styled(YouText)`
  justify-content: flex-start;
  margin-left: -6px;
`;

const Bubble = styled.div`
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 4.878px solid #727373;
  border-radius: 3rem;
`;

const InnerIcons = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #141515;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Main = styled.section`
  min-height: calc(100vh - 76px);
  background: #faf9f7;
  @media ${tablet} {
    min-height: calc(100vh - 68px);
  }
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const IconsContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 1.3rem;
  @media ${tablet} {
  }
`;

const Container = styled.div`
  padding: 2rem 1rem 2rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem 6rem;
  }
`;

const SvgContainer = styled.section`
  position: relative;
  width: 100%;
  margin: 0 auto;
  border-radius: 0.75rem;
  display: flex;
  max-width: 28.75rem;
  padding: 2.5rem 0.5rem 2.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 6px 12px 3px rgba(29, 36, 48, 0.05);
  @media ${tablet} {
    padding: 2.5rem 0rem 1rem;
  }
`;

const Title = styled.h2`
  color: #141515;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 100%;
  span {
    color: #03a62c;
  }
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.0135rem;
  }
`;

const Subtitle = styled.p`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.5rem;
  width: 100%;
  text-align: center;
  span {
    font-weight: 700;
  }
  padding-bottom: 1.5rem;
  @media ${tablet} {
  }
`;

const BottomText = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 1.5rem;

  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    background: #faf9f7;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
